import React, { useState, useLayoutEffect } from "react"
import styled from "styled-components"
import Info from "./Info"
import History from "./History"
import { doQueryTest } from "../../lib/util"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const Account = () => {
  const [active, setActive] = useState(0)

  useLayoutEffect(() => {
    doQueryTest("&o", () => setActive(1))
  }, [])
  return (
    <>
      <TabItems>
        <Item onClick={() => setActive(0)} active={active === 0}>
          Account Information
        </Item>
        <Item onClick={() => setActive(1)} active={active === 1}>
          Order History
        </Item>
      </TabItems>

      <Panel panelActive={active === 0}>
        <Info key="info-panel" />
      </Panel>
      <Panel panelActive={active === 1}>
        <History key="history-panel" />
      </Panel>
    </>
  )
}

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================

const TabItems = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: -53px;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    top: -52px;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    top: -42px;
  }
`
const Item = styled.div`
  padding: 10px 20px;
  margin: 0 10px;
  background: ${({ active }) => (active ? "#f8f8f8" : "#ffffff87")};
  font-weight: 500;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  font-size: 22px;
  transition: 0.2s all ease-in-out;
  box-shadow: ${({ active }) =>
    active ? 0 : "inset 0px -2px 2px 0px #0000001c"};

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    font-size: 15px;
    padding: 10px;
  }

  &:hover {
    ${({ active }) => !active && "background: #ffffffad;"};
  }
`

const Panel = styled.div`
  overflow: hidden;
  max-height: ${({ panelActive }) => (panelActive ? "700vh" : "0")};
  opacity: ${({ panelActive }) => (panelActive ? "1" : "0")};
  height: 100%;
  transition: all 0.4s ease-in-out;
`

export default Account
