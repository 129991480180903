import React from "react"
import { useEffect, memo } from "react"
import styled from "styled-components"
import Badge from "./Badge"
import { LinkWrapper as Link } from "../../../utils/linkWrapper"
import { formatMoney, getIndexOfKey } from "../../../lib/util"
import { slugify, titleCase, replaceAll } from "voca"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const Item = memo(
  ({
    name = "",
    total,
    quantity,
    sku,
    image,
    attributes,
    title = "",
    meta_data,
    size,
    setCount,
    urlsAvailable = [],
  }) => {
    const isBundledItem = meta_data[getIndexOfKey(meta_data, "_bundled_by")]
      ? meta_data[getIndexOfKey(meta_data, "_bundled_by")].key == "_bundled_by"
        ? true
        : false
      : false
    const isBundle = meta_data[getIndexOfKey(meta_data, "_bundled_items")]
      ? meta_data[getIndexOfKey(meta_data, "_bundled_items")].key ==
        "_bundled_items"
        ? true
        : false
      : false
    useEffect(() => {
      if (!isBundledItem) {
        setCount((prevState) => {
          return prevState + quantity
        })
      }
    }, [])

    let displayTitle = title ? title : name

    // Build product URL
    let productUrl = `/product/${slugify(displayTitle)}/`

    productUrl = urlsAvailable.some((url) => url.path === productUrl)
      ? productUrl
      : false
    productUrl =
      productUrl && size ? `${productUrl}?size=${slugify(size)}` : productUrl

    if (size && !displayTitle.includes(size)) {
      displayTitle = `${displayTitle} - ${titleCase(
        replaceAll(size, "-", " ")
      )}`
    }

    const OrderContents = () => {
      return (
        <Details>
          <div dangerouslySetInnerHTML={{ __html: image }} />
          <div className="txt">
            <h5>{displayTitle}</h5>
            <Badge label={sku} color="grey" />
          </div>
        </Details>
      )
    }

    return (
      !isBundledItem && (
        <Container>
          {productUrl ? (
            <Link to={productUrl}>
              <OrderContents />
            </Link>
          ) : (
            <OrderContents />
          )}
          <Price>
            {quantity} <span>x</span> {formatMoney(total / quantity)}
          </Price>
        </Container>
      )
    )
  }
)

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div`
  padding: 20px;
  &:not(:last-child) {
    border-bottom: 2px dashed #e6e6e6;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
  }
`

const Details = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 150px;
    min-width: 150px;
    margin-right: 20px;
    box-shadow: 0 2px 7px -4px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    overflow: hidden;
  }
  .txt {
    margin-right: 20px;
  }
  .text {
    display: flex;
    flex-direction: column;
  }
  h5 {
    font-size: 20px;
    font-weight: 500;
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    h5 {
      font-size: 16px;
    }
    img {
      width: 100px;
      min-width: 100px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    align-items: flex-start;
  }
`

const Price = styled.div`
  font-size: 20px;
  font-weight: 500;
  white-space: pre;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    font-size: 18px;
  }
  span {
    color: #9ba5ae;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    margin-left: 120px;
    margin-top: 10px;
  }
`

export default Item
