import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Order from "./Order"
import OrdersList from "./OrdersList"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const History = ({ errors, touched }) => {
  const data = useStaticQuery(URLS)
  const urlsAvailable =
    (data && data.allSitePage && data.allSitePage.nodes) || []
  return (
    <Container>
      {/* <Order />
			<Order />
			<Order /> */}
      <OrdersList
        errors={errors}
        touched={touched}
        urlsAvailable={urlsAvailable}
      />
    </Container>
  )
}
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div``

export default History

const URLS = graphql`
  {
    allSitePage {
      nodes {
        path
      }
    }
  }
`
