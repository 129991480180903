import React, { useLayoutEffect, useContext, useState } from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
import SEO from "../components/SEO/seo"
import AuthContext from "../context/AuthContext"
import Layout from "../zzz/layout"
import Inner from "../zzz/layout/pageInner/Inner"
import Heading from "../zzz/organisms/heading"
import EllipsisLoader from "../zzz/organisms/ellipsisLoader"
import styled from "styled-components"
import "react-tabs/style/react-tabs.css"
import Account from "../components/Account"
import { getUser } from "../services/auth"

const isBrowser = () =>
  typeof window !== "undefined" && typeof window.screen !== "undefined"

const AccountPage = ({ location }) => {
  const { loggedInState } = useContext(AuthContext)
  const [email, setEmail] = useState("")
  const data = useStaticQuery(PAGE)
  const seo = data.page.seo

  // Check if user is not logged in and show their email address
  useLayoutEffect(() => {
    if (loggedInState === false) {
      navigate("/login/")
    } else if (loggedInState === true) {
      if (isBrowser()) {
        const user = getUser()
        if (user && user.user_email) {
          setEmail(user.user_email)
        }
      }
    }
  }, [loggedInState])

  return (
    <Layout location={location}>
      <SEO title="My Account" location={location} post_id={20} seo={seo} />
      <Heading location={location} titleExtra={email} />
      <Container>
        <Inner>
          {loggedInState === true && <Account />}
          {!loggedInState && <EllipsisLoader />}
        </Inner>
      </Container>
    </Layout>
  )
}

const PAGE = graphql`
  {
    page: localWpGraphQlPages(pageId: { eq: 20 }) {
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
    }
  }
`

export default AccountPage

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div`
  position: relative;
  background: #f8f8f8;
  padding: 60px 0;
  min-height: 300px;
`
